import React from "react";
import { Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { keyframes } from "@emotion/react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

// Animation Keyframes
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const NoDataCard: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={3}
      sx={{
        animation: `${fadeIn} 1.5s ease-in-out`,
      }}
    >
      {/* Carte avec animation */}
      <Card
        sx={{
          maxWidth: 500,
          textAlign: "center",
          borderRadius: "20px",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
          bgcolor: "#ffffff",
          p: 3,
          transform: "scale(1)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <CardContent>
          {/* Emoji animé */}
          <Box
            mb={2}
            sx={{
              animation: `${bounce} 2s infinite`,
            }}
          >
            <SentimentVeryDissatisfiedIcon
              fontSize="large"
              sx={{
                fontSize: 80,
                color: "#ff6f61",
              }}
            />
          </Box>

          {/* Titre principal */}
          <Typography
            variant="body1"
            fontWeight="bold"
            color="#ff6f61"
            gutterBottom
            sx={{
              letterSpacing: "1px",
            }}
          >
            😢 Aucun contenu trouvé !
          </Typography>

          {/* Texte secondaire */}
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Oups ! On dirait qu'il n'y a rien ici pour l'instant.
          </Typography>

          {/* Effet étoile pour embellir */}
          <Box my={3}>
            <IconButton
              sx={{
                bgcolor: "#ffeef5",
                color: "#ff6f61",
                "&:hover": {
                  bgcolor: "#ffccd4",
                },
                animation: `${bounce} 1.5s infinite`,
              }}
            >
              <AutoAwesomeIcon sx={{ fontSize: 50 }} />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NoDataCard;
